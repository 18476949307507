import { graphql } from "gatsby";
import { arrayOf, exact, string } from "prop-types";
import React from "react";

import {
	CallToActionPropTypes,
	KeyFeaturePropTypes,
	ImagePropTypes,
	ImageArrayPropTypes,
	TestimonialPropTypes,
	ResortGroupsPropTypes,
} from "~/types";
import CallToAction from "Components/CallToAction";
import CircularImage from "Components/CircularImage";
import KeyFeatures from "Components/KeyFeatures";
import MinPageHeightWrapper from "Components/MinPageHeightWrapper";
import Testimonial from "Components/Testimonial";
import { EMPTY_ARR } from "Services/constants/empty";
import withDisplayAware from "Services/withDisplayAware";
import FlaikCycle from "./elements/FlaikCycle";
import FlaikFlow from "./elements/FlaikFlow";
import ResortGroups from "./elements/ResortGroups";
import Styles from "./styles.css";
import RequestDemo from "Components/RequestDemo";

const headerCircleImageProps = {
	small: {
		offsetX: 0,
		size: 600,
	},
	medium: {
		offsetX: 0,
		size: 600,
	},
	large: {
		offsetX: 0,
		offsetY: -35,
		size: 900,
	},
	xlarge: {
		offsetX: 0,
		offsetY: -35,
		size: 900,
	},
};

const HomepageContent = (props) => {
	const {
		body,
		headerImage,
		headerStrap,
		images,
		resortGroups,
		resortIntroText,
		features,
		flowDiagramIntroText,
		size,
		testimonial,
		callToAction,
	} = props;
	return (
		<>
			<MinPageHeightWrapper style={Styles.wrapper}>
				<h2>{body}</h2>
				<p css={Styles.headerStrap}>{headerStrap}</p>
				{<RequestDemo {...callToAction} />}
				<div css={Styles.videoContainer}>
				<iframe
						src="https://www.youtube.com/embed/DIWnYjkw0mI?si=a1EmNYsbvHRZJ-QR&amp;start=9"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowFullScreen
						css={Styles.videoIFrame}
						title="flaik - an intro"></iframe>
				</div>
				<CircularImage
					{...headerCircleImageProps[size]}
					fluid={headerImage.fluid}
					title={headerImage.title}
				/>
			
			</MinPageHeightWrapper>
			<MinPageHeightWrapper>
				<FlaikCycle />
			</MinPageHeightWrapper>
			<ResortGroups body={resortIntroText} groups={resortGroups || EMPTY_ARR} />
			{features && (
				<KeyFeatures features={features} images={images} pageSlug="home" />
			)}
			{testimonial && <Testimonial isFullBlock {...testimonial} />}
			<MinPageHeightWrapper>
				<FlaikFlow text={flowDiagramIntroText} />
			</MinPageHeightWrapper>
			{callToAction && <CallToAction {...callToAction} />}
			<script src="https://player.vimeo.com/api/player.js"></script>
		</>
	);
};


HomepageContent.defaultProps = {
	body: "",
	headerStrap: "",
};

HomepageContent.propTypes = {
	body: string,
	size: string,
	headerImage: exact(ImagePropTypes),
	headerStrap: string,
	images: ImageArrayPropTypes,
	resortIntroText: string,
	flowDiagramIntroText: string,
	features: arrayOf(exact(KeyFeaturePropTypes)),
	resortGroups: ResortGroupsPropTypes,
	testimonial: exact(TestimonialPropTypes),
	callToAction: exact(CallToActionPropTypes),
};

export default withDisplayAware("size")(HomepageContent);

export const query = graphql`
	fragment HomePageQuery on ContentfulPageHome {
		title
		description {
			description
		}
		headerImage {
			fluid(maxWidth: 900) {
				sizes
				src
				srcSet
			}
			title
		}
		headerStrap
		resortIntroText
		flowDiagramIntroText
		resortGroups {
			id
			title
			logo {
				fluid(maxWidth: 360) {
					sizes
					src
					srcSet
				}
				title
			}
			isLogoVisible
			resorts {
				id
				title
				website
				logo {
					fluid(maxWidth: 360) {
						sizes
						src
						srcSet
					}
					title
				}
			}
		}
		features {
			...KeyFeatureQuery
		}
		testimonial {
			...TestimonialQuery
		}
		callToAction {
			...CallToActionQuery
		}
	}
`;
